import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

//RESOURCES
import './css/_reset.scss';

//PAGES
import Header from './components/Header';
import Scroll from './components/Scroll';
import PlacesIn from './components/PlacesIn';
import Shedule from './components/Schedule';
import Benefits from './components/Benefits';
import Contact from './components/Contact';
import Footer from './components/Footer';

class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    };
    render() {
        return (
            <>

                <Helmet>
                    <title>Empreendimento Busca - Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h</title>
                    <meta name="description" content="Conheça o empreendimento Fazenda Serra Azul Santa Maria. More em um lugar onde seus filhos terão liberdade, amigos, quadras e mais com segurança 24h." />
                    <meta name="robots" content="noodp" />
                    <link rel="canonical" href="https://www.fazendaserrazul.com.br/pt/empreendimento/" />
                    <meta property="og:locale" content="pt_BR" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Empreendimento Busca - Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h" />
                    <meta property="og:description" content="Conheça o empreendimento Fazenda Serra Azul Santa Maria. More em um lugar onde seus filhos terão liberdade, amigos, quadras e mais com segurança 24h." />
                    <meta property="og:url" content="https://www.fazendaserrazul.com.br/pt/empreendimento/" />
                    <meta property="og:site_name" content="Fazenda SerrAzul - Senpar" />
                    <meta property="article:publisher" content="https://www.facebook.com/senparterras/" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content="Conheça o empreendimento Fazenda Serra Azul Santa Maria. More em um lugar onde seus filhos terão liberdade, amigos, quadras e mais com segurança 24h." />
                    <meta name="twitter:title" content="Empreendimento Busca - Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h" />
                </Helmet>

                <Header className="white" />
                <Scroll />
                <PlacesIn />
                <Shedule text="Agende uma visita. Sem Compromisso" className="small" />
                <Benefits />
                <Contact />
                <Footer />
            </>
        )
    }
}

export default Home;