import React, { Component } from 'react';
import { Link } from "react-scroll";

//RESOURCES
import './../css/videoMain.scss';

class VideoMain extends Component {
    render() {
        return (
            <section id="videoMain" className="section reset">
                <figure>
                    <iframe title="Vídeo Fazenda" width="100%" height="100%" src="https://www.youtube.com/embed/_DpPb_QuGeQ?autoplay=1&mute=1&controls=0&loop=1&playlist=_DpPb_QuGeQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </figure>
                <div className="content">
                    <div className="card">
                        <p>
                            Venha Conhecer o Empreendimento<br />
                            <span className="middle">Lotes de 800 a 1.200m² a 30 minutos de São Paulo</span>
                            <span className="bottom">(Pela Rodovia dos Bandeirantes)</span>
                        </p>
                        {/* <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >Agende uma visita</Link> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default VideoMain;