import React, { Component } from "react";

//RESOURCES
import "./../css/placesIn.scss";

//IMAGES
import map from "./../images/maps-map.png";
import mapA from "./../images/location-maps-letter-a.png";
import arrrowA from "./../images/location-maps-letter-a-arrow.png";
import mapB from "./../images/location-maps-letter-b.png";
import arrrowB from "./../images/location-maps-letter-b-arrow.png";
import mapC from "./../images/location-maps-letter-c.png";
import arrrowC from "./../images/location-maps-letter-c-arrow.png";

//sede-esporte
import mapD from "./../images/location-maps-letter-d.png";
import arrrowD1 from "./../images/location-maps-letter-d-arrow-serr1.png";
import arrrowD2 from "./../images/location-maps-letter-d-arrow-serr2.png";
import arrrowD from "./../images/location-maps-letter-d-arrow.png";

import mapE from "./../images/location-maps-letter-e.png";
import arrrowE from "./../images/location-maps-letter-e-arrow.png";
import mapF from "./../images/location-maps-letter-f.png";
import arrrowF2 from "./../images/location-maps-letter-f-arrow-serr2.png";
import arrrowF1 from "./../images/location-maps-letter-f-arrow-serr1.png";
import arrrowF from "./../images/location-maps-letter-f-arrow.png";
//quadra
import mapG from "./../images/location-maps-letter-g.png";
import arrowG1 from "./../images/location-maps-letter-g-arrow-serr1.png";
import arrowG2 from "./../images/location-maps-letter-g-arrow-serr2.png";
import arrowG from "./../images/location-maps-letter-g-arrow.png";

//quadrastennis
import mapH from "./../images/location-maps-letter-h.png";
import arrrowH1 from "./../images/location-maps-letter-h-arrow-serr1.png";
import arrrowH2 from "./../images/location-maps-letter-h-arrow-serr2.png";
import arrrowH from "./../images/location-maps-letter-h-arrow.png";

//campofut
import mapI from "./../images/location-maps-letter-i.png";
import arrrowI1 from "./../images/location-maps-letter-i-arrow-serr1.png";
import arrrowI2 from "./../images/location-maps-letter-i-arrow-serr2.png";
import arrrowI from "./../images/location-maps-letter-i-arrow.png";

//portaria
import mapJ from "./../images/location-maps-letter-j.png";
import arrrowJ1 from "./../images/location-maps-letter-j-arrow-serr1.png";
import arrrowJ2 from "./../images/location-maps-letter-j-arrow-serr2.png";
import arrrowJ from "./../images/location-maps-letter-j-arrow.png";

import mapL from "./../images/location-maps-letter-l.png";
import arrrowL from "./../images/location-maps-letter-l-arrow.png";

import mapFull from "./../images/map-full.png";
import serrAzul1 from "./../images/map-serrazul-1.png";
import serrAzul2 from "./../images/map-serrazul-2.png";

class Maps extends Component {
  constructor() {
    super();
    this.ChangeActive = this.ChangeActive.bind(this);
    this.changeMap = this.changeMap.bind(this);
    this.state = {
      mapa: "",
      map: "",
      title: "",
      active: 0,
      x: "",
      y: "",
      width: "",
      serranum: 3
    };
  }

  ChangeActive(event, index) {
    event.preventDefault();

    this.setState({
      map: event.target.getAttribute("img"),
      arrow: event.target.getAttribute("arrow"),
      title: event.target.getAttribute("title"),
      active: index,
      x: event.target.getAttribute("x"),
      y: event.target.getAttribute("y"),
      width: event.target.getAttribute("width")
        ? parseInt(event.target.getAttribute("width"))
        : 490
    });
  }

  changeMap(event) {
    event.preventDefault();
    this.setState({ map: "", title: "", active: 0, width: 0, serranum: 0 });

    let a = parseInt(event.target.value);
    this.setState({serranum : a})

    let button = event.target,
      buttonOld = document.querySelectorAll(".button"),
      target = button.getAttribute("data-map"),
      map = document.querySelectorAll(".map");

    map.forEach(element => {
      element.classList.remove("js-active");
    });

    buttonOld.forEach(element => {
      element.classList.remove("js-active");
    });

    button.classList.add("js-active");
    document.querySelectorAll(`.${target}`)[0].classList.add("js-active");
    console.log(this.state);
  }

  componentDidMount() {
    // this.setState({
    //   map: mapA,
    //   arrow: arrrowA,
    //   title: "Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)",
    //   active: 1,
    //   x: 33,
    //   y: 48,
    //   width: 490
    // });
  }

  render() {
    return (
      <section id="maps" className="section reset">
        <div className="content">
          <h1>
            Muito mais que um condominio.
            <br />
            Um loteamento fechado de alto padrão
          </h1>
          <h2>
            Um empreendimento com toda a qualidade e credibilidade do <br />
            Grupo Senpar Terras de São José, idealizador do primeiro loteamento
            fechado do Brasil.
          </h2>
          <div className="content-maps">
            <div className="maps">
              <figure className="maps merge">
                <img src={mapFull} alt="" title="" className="map full" />
                <img
                  src={serrAzul1}
                  alt=""
                  title=""
                  className="map serrAzul-1"
                />
                <img
                  src={serrAzul2}
                  alt=""
                  title=""
                  className="map serrAzul-2"
                />
              </figure>

              <figure
                className={this.state.map === "" ? "hidden" : "thumb"}
                style={{ top: this.state.x + "%", left: this.state.y + "%" }}
              >
                <img
                  src={this.state.map}
                  alt={this.state.title}
                  title={this.state.title}
                />
                <img
                  src={this.state.arrow}
                  alt={this.state.title}
                  title={this.state.title}
                  className="arrow"
                  style={{ maxWidth: this.state.width }}
                />
                <figcaption>
                  <p>{this.state.title}</p>
                </figcaption>
              </figure>

              <div className="container-button title">
                <p className="button-title">
                  CONHEÇA A ÁREA TOTAL DE CADA EMPREENDIMENTO:
                </p>
              </div>
              <div className="container-button">
              <button
                  className="button js-active"
                  data-map="full"
                  value={3}
                  onClick={(this.reset, this.changeMap)}
                >
                  Loteamento SerrAzul
                </button>
                <button
                  className="button"
                  data-map="serrAzul-1"
                  value={1}
                  onClick={(this.reset, this.changeMap)}
                >
                  SerrAzul I
                </button>
                <button
                  className="button"
                  data-map="serrAzul-2"
                  value={2}
                  onClick={(this.reset, this.changeMap)}
                >
                  SerrAzul II
                </button>
              </div>
            </div>

            <div className="legend">
              <div className="aligndiv">
                <p style={{ color: "white" }}>
                  {this.state.serranum === 0
                    ? ""
                    : "CLIQUE NA LEGENDA E CONHEÇA MELHOR O LOTEAMENTO"}
                </p>
              </div>
              <nav>
                <ul
                  style={{ margin: 0 }}
                  className={this.state.serranum === 1 ? "active" : "hidden"}
                >
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 1);
                      }}
                      img={mapA}
                      arrow={arrrowA}
                      title="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)"
                      className={this.state.active === 1 ? "active" : ""}
                      x="33"
                      y="48"
                    >
                      A
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 2);
                      }}
                      img={mapB}
                      arrow={arrrowB}
                      title="Fazenda SerrAzul - Lago"
                      className={this.state.active === 2 ? "active" : ""}
                      x="29"
                      y="54"
                    >
                      B
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 4);
                      }}
                      img={mapD}
                      arrow={arrrowD1}
                      title="Fazenda SerrAzul - Sede de Esporte"
                      className={this.state.active === 4 ? "active" : ""}
                      x="39"
                      y="48"
                      width="470"
                    >
                      C
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 8);
                      }}
                      img={mapH}
                      arrow={arrrowH1}
                      title="Fazenda SerrAzul - 4 Quadras de Tênis de Saibro"
                      className={this.state.active === 8 ? "active" : ""}
                      x="38"
                      y="50"
                    >
                      D
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 6);
                      }}
                      img={mapF}
                      arrow={arrrowF2}
                      title="Fazenda SerrAzul - Playground"
                      className={this.state.active === 6 ? "active" : ""}
                      x="40"
                      y="47"
                    >
                      E
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 9);
                      }}
                      img={mapI}
                      arrow={arrrowI2}
                      title="Fazenda SerrAzul - Campo de Futebol com grama natural"
                      className={this.state.active === 9 ? "active" : ""}
                      x="25"
                      y="50"
                      width="435"
                    >
                      F
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 7);
                      }}
                      img={mapG}
                      arrow={arrowG1}
                      title="Fazenda SerrAzul - Quadra Poliesportiva"
                      className={this.state.active === 7 ? "active" : ""}
                      x="25"
                      y="49"
                      width="365"
                    >
                      G
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 11);
                      }}
                      img={mapL}
                      arrow={arrrowL}
                      title="Fazenda SerrAzul - CHESA (pay-per-use) - Centro Hípico de Excelência SerrAzul"
                      className={this.state.active === 11 ? "active" : ""}
                      x="9"
                      y="67"
                    >
                      H
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 10);
                      }}
                      img={mapJ}
                      arrow={arrrowJ1}
                      title="Fazenda SerrAzul - Portaria"
                      className={this.state.active === 10 ? "active" : ""}
                      x="31"
                      y="65"
                      width="300"
                    >
                      I
                    </a>
                  </li>
                </ul>
                <ul className={this.state.serranum === 2 ? "active" : "hidden"}>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 1);
                      }}
                      img={mapA}
                      arrow={arrrowA}
                      title="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)"
                      className={this.state.active === 1 ? "active" : ""}
                      x="33"
                      y="48"
                    >
                      A
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 2);
                      }}
                      img={mapB}
                      arrow={arrrowB}
                      title="Fazenda SerrAzul - Lago"
                      className={this.state.active === 2 ? "active" : ""}
                      x="29"
                      y="54"
                    >
                      B
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 7);
                      }}
                      img={mapG}
                      arrow={arrowG2}
                      title="Fazenda SerrAzul - Quadra Poliesportiva"
                      className={this.state.active === 7 ? "active" : ""}
                      x="17"
                      y="49"
                      width="365"
                    >
                      C
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 3);
                      }}
                      img={mapC}
                      arrow={arrrowC}
                      title="Fazenda SerrAzul - Fitness Center"
                      className={this.state.active === 3 ? "active" : ""}
                      x="14"
                      y="52"
                    >
                      D
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 8);
                      }}
                      img={mapH}
                      arrow={arrrowH2}
                      title="Fazenda SerrAzul - 4 Quadras de Tênis de Saibro"
                      className={this.state.active === 8 ? "active" : ""}
                      x="36"
                      y="51"
                    >
                      E
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 5);
                      }}
                      img={mapE}
                      arrow={arrrowE}
                      title="Fazenda SerrAzul - Piscina com raia"
                      className={this.state.active === 5 ? "active" : ""}
                      x="14"
                      y="50"
                    >
                      F
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 9);
                      }}
                      img={mapI}
                      arrow={arrrowI1}
                      title="Fazenda SerrAzul - Campo de Futebol com grama natural"
                      className={this.state.active === 9 ? "active" : ""}
                      x="21"
                      y="52"
                      width="435"
                    >
                      G
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 4);
                      }}
                      img={mapD}
                      arrow={arrrowD2}
                      title="Fazenda SerrAzul - Sede de Esporte"
                      className={this.state.active === 4 ? "active" : ""}
                      x="35"
                      y="51"
                      width="470"
                    >
                      H
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 10);
                      }}
                      img={mapJ}
                      arrow={arrrowJ2}
                      title="Fazenda SerrAzul - Portaria"
                      className={this.state.active === 10 ? "active" : ""}
                      x="25"
                      y="68"
                      width="300"
                    >
                      I
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 11);
                      }}
                      img={mapL}
                      arrow={arrrowL}
                      title="Fazenda SerrAzul - CHESA (pay-per-use) - Centro Hípico de Excelência SerrAzul"
                      className={this.state.active === 11 ? "active" : ""}
                      x="9"
                      y="67"
                    >
                      J
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 6);
                      }}
                      img={mapF}
                      arrow={arrrowF1}
                      title="Fazenda SerrAzul - Playground"
                      className={this.state.active === 6 ? "active" : ""}
                      x="37"
                      y="48"
                    >
                      K
                    </a>
                  </li>
                </ul>
                {/* THIRD STATES */}
                <ul
                  style={{ margin: 0 }}
                  className={this.state.serranum === 3 ? "active" : "hidden"}
                >
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 1);
                      }}
                      img={mapA}
                      arrow={arrrowA}
                      title="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)"
                      className={this.state.active === 1 ? "active" : ""}
                      x="33"
                      y="48"
                    >
                      A
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 2);
                      }}
                      img={mapB}
                      arrow={arrrowB}
                      title="Fazenda SerrAzul - Lago"
                      className={this.state.active === 2 ? "active" : ""}
                      x="29"
                      y="54"
                    >
                      B
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 3);
                      }}
                      img={mapD}
                      arrow={arrrowD}
                      title="Fazenda SerrAzul - Sede de Esportes"
                      className={this.state.active === 3 ? "active" : ""}
                      x="38"
                      y="50"
                      width="470"
                    >
                      C
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 4);
                      }}
                      img={mapH}
                      arrow={arrrowH}
                      title="Fazenda SerrAzul - 4 Quadras de Tênis de Saibro"
                      className={this.state.active === 4 ? "active" : ""}
                      x="38"
                      y="50"
                    >
                      D
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 5);
                      }}
                      img={mapF}
                      arrow={arrrowF}
                      title="Fazenda SerrAzul - Playground"
                      className={this.state.active === 5 ? "active" : ""}
                      x="40"
                      y="47"
                    >
                      E
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 10);
                      }}
                      img={mapC}
                      arrow={arrrowC}
                      title="Fazenda SerrAzul - Fitness Center"
                      className={this.state.active === 10 ? "active" : ""}
                      x="14"
                      y="52"
                    >
                      F
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 6);
                      }}
                      img={mapI}
                      arrow={arrrowI}
                      title="Fazenda SerrAzul - Campo de Futebol com grama natural"
                      className={this.state.active === 6 ? "active" : ""}
                      x="19"
                      y="50"
                      width="435"
                    >
                      G
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 7);
                      }}
                      img={mapG}
                      arrow={arrowG}
                      title="Fazenda SerrAzul - Quadra Poliesportivas"
                      className={this.state.active === 7 ? "active" : ""}
                      x="17"
                      y="49"
                      width="365"
                    >
                      H
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 8);
                      }}
                      img={mapL}
                      arrow={arrrowL}
                      title="Fazenda SerrAzul - CHESA (pay-per-use) - Centro Hípico de Excelência SerrAzul"
                      className={this.state.active === 8 ? "active" : ""}
                      x="9"
                      y="67"
                    >
                      I
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 9);
                      }}
                      img={mapJ}
                      arrow={arrrowJ}
                      title="Fazenda SerrAzul - Portarias"
                      className={this.state.active === 9 ? "active" : ""}
                      x="28"
                      y="64"
                      width="300"
                    >
                      J
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={event => {
                        this.ChangeActive(event, 11);
                      }}
                      img={mapE}
                      arrow={arrrowE}
                      title="Fazenda SerrAzul - Piscina com raia"
                      className={this.state.active === 11 ? "active" : ""}
                      x="14"
                      y="50"
                    >
                      K
                    </a>
                  </li>
              
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Maps;
