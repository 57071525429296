import React, { Component } from 'react';

//RESOURCES
import './../css/closeEverything.scss';
import imgLocation from './../images/locationmap-bg.png';

class CloseEverything extends Component {
  render() {
    return (
      <section id="closeEverything" className="section reset">
        <figure className="bg">
          <img src={imgLocation} alt="" title="" />
        </figure>
        <div className="text">
          <h1>
            Fazenda SerrAzul, Natureza<br />
            Exuberante e Perto de Tudo
          </h1>
          <h2>
            A apenas 30 minutos de São Paulo e 10 minutos de Jundiaí. O Fazenda SerrAzul é um<br />
            loteamento fechado de alto padrão com terrenos de 800 a 1.200m²
          </h2>
        </div>
        <ul>
          <li>
            <figure>
              <i className="aviao"></i>
              <figcaption>
                <h3>
                  <strong>Viracopos</strong>
                  20 minutos
                </h3>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <i className="predio"></i>
              <figcaption>
                <h3>
                  <strong>Campinas</strong>
                  15 minutos
                </h3>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <i className="parque"></i>
              <figcaption>
                <h3>
                  <strong>Jundiaí</strong>
                  10 minutos
                </h3>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <i className="predio"></i>
              <figcaption>
                <h3>
                  <strong>São Paulo</strong>
                  30 minutos
                </h3>
              </figcaption>
            </figure>
          </li>
        </ul>
      </section>
    );
  }
}

export default CloseEverything;
