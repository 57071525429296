import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

//RESOURCES
import './css/_reset.scss';

//PAGES
import Header from './components/Header';
import Scroll from './components/Scroll';
import CloseEverything from './components/CloseEverything';
import Shedule from './components/Schedule';
import PlacesOut from './components/PlacesOut';
import Contact from './components/Contact';
import Footer from './components/Footer';

class Localizacao extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    };
    render() {
        return (
            <>

                <Helmet>
                    <title>Localização - Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h</title>
                    <meta name="description" content="Com vizinhos de prestígio, O Fazenda Serrazul fica, ao mesmo tempo, longe e perto de tudo. Ao passar os portões você terá acesso a tudo o que precisa." />
                    <meta name="robots" content="noodp" />
                    <link rel="canonical" href="https://www.fazendaserrazul.com.br/pt/localizacao/" />
                    <meta property="og:locale" content="pt_BR" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Localização - Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h" />
                    <meta property="og:description" content="Com vizinhos de prestígio, O Fazenda Serrazul fica, ao mesmo tempo, longe e perto de tudo. Ao passar os portões você terá acesso a tudo o que precisa." />
                    <meta property="og:url" content="https://www.fazendaserrazul.com.br/pt/localizacao/" />
                    <meta property="og:site_name" content="Fazenda SerrAzul - Senpar" />
                    <meta property="article:publisher" content="https://www.facebook.com/senparterras/" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content="Com vizinhos de prestígio, O Fazenda Serrazul fica, ao mesmo tempo, longe e perto de tudo. Ao passar os portões você terá acesso a tudo o que precisa." />
                    <meta name="twitter:title" content="Localização - Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h" />
                </Helmet>

                <Header />
                <Scroll />
                <CloseEverything />
                <Shedule text="Agende uma visita. Sem Compromisso" className="small" />
                <PlacesOut />
                <Contact />
                <Footer />
            </>
        )
    }
}

export default Localizacao;