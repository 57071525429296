import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import Scroll from './components/Scroll';
import VideoMain from './components/VideoMain' ;
import Development from './components/Development';
import Senpar from './components/Senpar';
import Measure from './components/Measure';
import Location from './components/Location-map';
import Gallery from './components/Gallery';
import Shedule from './components/Schedule';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Fixed_Anapro from './components/Fixed_anapro';
import History from './components/History';
import Timeline from './components/Timeline';

class realizacao extends Component {
    constructor(props) {
        super(props);

    }

    
    render() {
        return (
            <>
                <Helmet>
                    <title>Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h</title>
                    <meta name="description" content="Seus dias mais azuis. Onde é possível abrir a janela e contemplar a maravilhosa vista para a Serra do Japi em um loteamento fechado com segurança 24h." />
                    <meta property="og:locale" content="pt_BR" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h" />
                    <meta property="og:description" content="Seus dias mais azuis. Onde é possível abrir a janela e contemplar a maravilhosa vista para a Serra do Japi em um loteamento fechado com segurança 24h." />
                    <meta property="og:url" content="https://www.fazendaserrazul.com.br/pt/" />
                    <meta property="og:site_name" content="Fazenda SerrAzul - Senpar" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content="Seus dias mais azuis. Onde é possível abrir a janela e contemplar a maravilhosa vista para a Serra do Japi em um loteamento fechado com segurança 24h." />
                    <meta name="twitter:title" content="Fazenda Serra Azul - Senpar - Condomínio fechado com Segurança 24h" />
                </Helmet>

                <Header className="transparence" />
                <Scroll />

                <History/>
                <Timeline/>

                <Contact />
                <Footer />
                <Fixed_Anapro/>
            </>
        );
    }
}


export default realizacao;