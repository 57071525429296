import React, { Component } from 'react';
import { Link } from "react-scroll";

//RESOURCES
import './../css/measure.scss';

class Measure extends Component {
    render() {
        return (
            <section id="measure" className="section reset">
                <div className="content">
                    <div className="flex">
                        <p>
                            <strong>
                                Terrenos de 800m² a 1,200m²
                                <span>Agende uma Visita sem Compromisso</span>
                            </strong>
                        </p>
                        <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >Agende uma visita</Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default Measure;
