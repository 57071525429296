import React, { Component } from 'react';

import './../css/Fixed_Anapro.scss';
import image from './../images/popup-anapro.png'

class Fixed_Anapro extends Component {
    // constructor() {
    //     super();
    //     this.state = {
    //       isClosed: false,
    //     }
        
    //     this.Close = this.Close.bind(this);
    //   }
        
    
    // handleClick(e){

    //     let url = "https://online.crm.anapro.com.br/WebCRMService/Pages/chat/cliente/v2/ChatClienteEntrada.aspx?conta=iYS41KZoeRg1&keyIntegradora=19EF3BEC-4B3B-48E0-89BF-FB0C111D4FDA&keyAgencia=cad9a630-e1b1-409c-b2b2-eafce3f2f5f0&strDir=senpar&campanha=I-Tkuj8v7Q81&canal=fIGEQPy7yAo1&produto=gJizs8B_X101&strmidia=Site&strpeca=&usuarioEmail=&strgrupopeca=&strcampanhapeca=&nome=&email=&telefoneDDD=&telefone=&strTexto=&keyexterno=&urlep=&urlcp=&urlca=&urlat=&strMostrarTopo=true&strAutoSubmit=true&strUsarDadosAnteriores=true&emailobrigatorio=true&telefoneobrigatorio=false&texto="

    //     e.preventDefault();
    //     var params = "width=500,height=750,";
    //     window.open(url, "Chat Online", params);

    // }

    // Close(){
    //     this.setState({
    //         isClosed : true
    //     })
    // }

    render() {
        return (
            <React.Fragment>
                {/* {
                    !this.state.isClosed && <img  className="anapro-chat-img" src={image} alt="" useMap="#chat"/>
                }

                <map name="chat">
                    <area onClick={this.handleClick} className="anapro-chat" shape="rect" coords="0,0,230,230" alt="test" title="chat"/>
                    <area onClick={this.Close} className="anapro-chat-close" shape="rect" coords="250,20,230,50"  alt="test" title="Fechar"/>
                </map> */}
            </React.Fragment>
        );  
    }
}


export default Fixed_Anapro;