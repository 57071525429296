import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//PAGES
import Home from './Home';
import Empreendimento from './Empreendimento';
import Localizacao from './Localizacao';
import Sucesso from './Sucesso';
import realizacao from './realizacao';
 

ReactDOM.render((
    <Router>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/empreendimento" component={Empreendimento} />
            <Route path="/localizacao" component={Localizacao} />
            <Route path="/sucesso" component={Sucesso} />
            <Route path="/realizacao" component={realizacao} />
        </Switch>
    </Router>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
