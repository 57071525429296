import React, { Component } from 'react';
import { Link } from "react-scroll";

//RESOURCES
import './../css/schedule.scss';

class Shedule extends Component {
    render() {
        return (
            <section id="shedule" className="section reset">
                <div className="content">
                    <p className={this.props.className}>
                        <strong>
                            {this.props.text}
                        </strong>
                        <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >Agende uma visita</Link>
                    </p>
                </div>
            </section>
        );
    }
}

export default Shedule;
