import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { Link } from 'react-router-dom';

//RESOURCES
import 'react-owl-carousel2/src/owl.carousel.css';
import './../css/development.scss';
import imgAlamedas from './../images/development-alamedas.png';
import imgCampos from './../images/development-campos.png';
import imgEnergizacao from './../images/development-energizacao (2).png';
// import imgHipismo from './../images/development-hipismo.png';
import imgLagos from './../images/development-lagos.png';
import imgPortaria from './../images/development-portaria.png';
import imgViario from './../images/development-sistema-viario.png';
import imgTenis from './../images/development-tenis.png';

//CUSTOM
const
    options = {
        items: 1,
        nav: true,
        rewind: true,
        autoplay: true,
        // lazyLoad: true,
        navText: [
            '<',
            '>'
        ]
    },
    images = [
        {

            img: imgPortaria,
            title: 'Fazenda SerrAzul - Vigilância 24 horas'
        },
        {
            img: imgTenis,
            title: 'Fazenda SerrAzul - 4 Quadras de Tênis'
        },
        {
            img: imgCampos,
            title: 'Fazenda SerrAzul - Campo de Futebol Society'
        },
        {
            img: imgLagos,
            title: 'Fazenda SerrAzul - Lagos'
        },
        {
            img: imgEnergizacao,
            title: 'Fazenda SerrAzul - Energização Subterrânea'
        },
        {
            img: imgViario,
            title: 'Fazenda SerrAzul - Sistema Viário Inteligente'
        },
        {
            img: imgAlamedas,
            title: 'Fazenda SerrAzul - Alamedas exclusivas'
        }
    ]

class Development extends Component {
    render() {
        return (
            <section id="development" className="section reset">
                <div className="text">
                    <div className="content">
                        <h1>
                            Loteamento Fazenda SerrAzul<br />
                            Terrenos Prontos para Construir
                        </h1>
                        <h2>
                            Muito mais que um condomínio, um loteamento fechado de alto padrão no interior de São Paulo.<br />
                            A apenas 30 minutos de São Paulo e 10 minutos de Jundiaí. Perto dos grandes centros.
                        </h2>
                        <p>
                            Imagine-se morando em um lugar onde seus filhos podem ter liberdade para andar de bicicleta pelas ruas, conviver com amigos, praticar esportes e ter contato com a natureza. Já pensou em abrir a janela e ter a exuberante vista para a Serra do Japi? O por do sol é um espetáculo a parte! Contar com as facilidades que só as grandes cidades oferecem é mais um diferencial do loteamento fechado Fazenda Serrazul. Completamente integrado ao Outlet Premium, Quality Resort Itupeva, Shopping aéreo Serrazul e ao Wet´n Wild.
                        </p>
                        <p>
                            Venha conhecer e construa sua casa a apenas 30 minutos de São Paulo e 10 minutos de Jundiaí!

                        </p>
                    </div>
                </div>
                <OwlCarousel options={options}>
                    {
                        images.map(function (image, index) {
                            return (
                                <div key={index}>
                                    <img src={image.img} alt={image.title} title={image.title} />
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
                <p>
                    <Link to='/empreendimento' title="Conheça o empreendimento">
                        Conheça o <br />empreendimento
                    </Link >
                </p>
            </section>
        )
    }
}


export default Development;