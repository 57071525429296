import React from 'react';
import '../css/history.scss';

const history = (props) => {

    return <>
        <section class="history content">
            <div class="container-flex" data-aos="fade-up">
                <h1 class="history__title">História de tradição e pioneirismo.<br/> Obras de infraestrutura, loteamentos,<br/> hotelaria e shoppings.</h1>
                <div class="history__text">
                    <p>A história do Grupo começa com um Brasil sem fronteiras, de riquezas inexploradas e com regiões isoladas do progresso. Era este o relato do Brasil na década de 60. Sempre acompanhando as mudanças de mercado, o Grupo expandiu seus negócios,
                        formando parcerias e investindo em concessões rodoviárias, Shopping Center, Hotéis e Entretenimento.</p>
                    <p>Ao longo destes anos o Grupo Senpar Terras de São José participou e participa em mais de 3.500 km de pistas construídas e mantidas. Hoje é um conjunto de empresas modernas e com história, deixando um legado de qualidade e progresso por onde
                        passa e que, assim como quando começou, acelera todos os dias o futuro e continua vendo um Brasil promissor para que todos possam crescer.</p>
                </div>
                <div class="history__awards">
                    <h3 class="history__subtitle">Prêmios</h3>
                    <ul class="history__awards-list">
                        <li>
                            <figure><img src="./images/history__awards-premio-master.jpg" alt="Premio Master" title="Premio Master" /></figure>
                        </li>
                        <li>
                            <figure><img src="./images/history__awards-premio-abcem.jpg" alt="Premio Abcen" title="Premio Abcen" /></figure>
                        </li>
                        <li>
                            <figure><img src="./images/history__awards-global-water.jpg" alt="Global Water Research Coalition" title="Global Water Research Coalition" /></figure>
                        </li>
                    </ul>
                </div>
                <div class="history__anchor"><a class="js--anchor" href="#" title="Conheça a nossa linha do tempo" data-content="timeline">Conheça a nossa linha do tempo</a></div>
            </div>
        </section>
    </>
}

export default history;