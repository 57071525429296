import React, { Component } from 'react';
import { render } from 'react-dom';
import {GoogleApiWrapper} from 'google-maps-react';

//RESOURCE
import './../css/placesOut.scss';
import pin from './../images/icon-pin-mapa.png';
import mercado from './../images/icon-mercado.png';
import farmacia from './../images/icon-farmacia.png';
import escola from './../images/icon-escola.png';
import padaria from './../images/icon-padaria.png';
import banco from './../images/icon-banco.png';
import diversao from './../images/icon-diversao.png';
import shopping from './../images/icon-shopping.png';
import hoteis from './../images/icon-hoteis.png';

//CUSTOM
const data = {
    default: {
        center: { lat: -23.1077705, lng: -47.0116533 },
        zoom: 10,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            }
        ]
    },
    supermercados: {
        center: { lat: -23.0997705, lng: -47.0116533 },
        zoom: 12,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            // {
            //     label: 'Supermercados Decler',
            //     position: {
            //         lat: -23.0906896,
            //         lng: -46.9771706
            //     },
            //     icon: mercado
            // },
            // {
            //     label: 'Mercado Monteiro',
            //     position: {
            //         lat: -23.1252899,
            //         lng: -47.010018
            //     },
            //     icon: mercado
            // },
            // {
            //     label: 'Santa Terezinha',
            //     position: {
            //         lat: -23.1393965,
            //         lng: -46.9952079
            //     },
            //     icon: mercado
            // },
            // {
            //     label: 'Mercado Aliança',
            //     position: {
            //         lat: -23.1336341,
            //         lng: -47.0024916
            //     },
            //     icon: mercado
            // },
            {
                label: 'Covabra',
                position: {
                    lat: -23.0544385,
                    lng: -46.9783545
                },
                icon: mercado
            }, 
            {
                label: 'Supermercado Monte Serrat',
                position: {
                    lat: -23.1520108,
                    lng: -47.0572329
                },
                icon: mercado
            }
        ]
    },
    farmacias: {
        center: { lat: -23.1077705, lng: -47.0116533 },
        zoom: 15,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            {
            label: 'Shopping SerrAzul',
            position: {
                lat: -23.1057736,
                lng: -47.0085662
            },
                icon: farmacia
            },
            // {
            //     label: 'Sorri Pharma',
            //     position: {
            //         lat: -23.1442705,
            //         lng: -46.9981119
            //     },
            //     icon: farmacia
            // },
            // {
            //     label: 'Drogaria Nova Farma',
            //     position: {
            //         lat: -23.1428606,
            //         lng: -47.0042572
            //     },
            //     icon: farmacia
            // },
            // {
            //     label: 'Drogaria Residencial',
            //     position: {
            //         lat: -23.1415933,
            //         lng: -47.0087768
            //     },
            //     icon: farmacia
            // },
            // {
            //     label: 'Farmacia Bem Estar',
            //     position: {
            //         lat: -23.1459748,
            //         lng: -47.0052189
            //     },
            //     icon: farmacia
            // },
        ]
    },
    escolas: {
        center: { lat: -23.1077705, lng: -47.0116533 },
        zoom: 10,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            // {
            //     label: 'Objetivo Itupeva - Escola Nova Vida',
            //     position: {
            //         lat: -23.1611028,
            //         lng: -47.0344509
            //     },
            //     icon: escola
            // },
            // {
            //     label: 'Colégio trevo',
            //     position: {
            //         lat: -23.1576532,
            //         lng: -47.0553788
            //     },
            //     icon: escola
            // },
            // {
            //     label: 'Escola de Educação Infantil Imaginarium',
            //     position: {
            //         lat: -23.0862014,
            //         lng: -46.947138
            //     },
            //     icon: escola
            // },
            // {
            //     label: 'Colégio Via Brasil',
            //     position: {
            //         lat: -23.0822411,
            //         lng: -46.9548771
            //     },
            //     icon: escola
            // },
            {
                label: 'Porto Seguro',
                position: {
                    lat: -22.9466171,
                    lng: -47.0283294
                },
                icon: escola
            },
            {
                label: 'Colégio Anglo Itupéva',
                position: {
                    lat: -23.1565522,
                    lng: -47.0638033
                },
                icon: escola
            },
            {
                label: 'Colégio Pentagunum Objetivo',
                position: {
                    lat: -23.1960523,
                    lng: -46.8968337
                },
                icon: escola
            },
            {
                label: 'Colégio Pentagunum Objetivo',
                position: {
                    lat: -23.1960523,
                    lng: -46.8968337
                },
                icon: escola
            },
            {
                label: 'Colégio Porto Seguro Valinhos',
                position: {
                    lat: -22.980846,
                    lng: -47.025217
                },
                icon: escola
            },
        ]
    },
    padarias: {
        center: { lat: -23.077705, lng: -47.0116533 },
        zoom: 10,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            // {
            //     label: 'Panificadora Almerinda',
            //     position: {
            //         lat: -23.1452271,
            //         lng: -46.9931828
            //     },
            //     icon: padaria
            // },
            // {
            //     label: 'Padaria Passarela do Pão',
            //     position: {
            //         lat: -23.0867305,
            //         lng: -46.9759905
            //     },
            //     icon: padaria
            // },
            // {
            //     label: 'Panificadora e Confeitaria Imperial',
            //     position: {
            //         lat: -23.0809213,
            //         lng: -46.9511195
            //     },
            //     icon: padaria
            // },
            // {
            //     label: 'TJ Padaria Confeitaria',
            //     position: {
            //         lat: -23.1454099,
            //         lng: -47.0044805
            //     },
            //     icon: padaria
            // },
            {
                label: 'Frango Assado',
                position: {
                    lat: -22.997380,
                    lng: -47.034270
                },
                icon: padaria
            },
            {
                label: 'Shopping SerrAzul',
                position: {
                    lat: -23.1057736,
                    lng: -47.0085662
                },
                icon: padaria
            }
            
        ]
    },
    bancos: {
        center: { lat: -23.1077705, lng: -47.0116533 },
        zoom: 11,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            {
                label: 'Banco 24 Horas',
                position: {
                    lat: -23.1081468,
                    lng: -47.0095938
                },
                icon: banco
            },
            {
                label: 'Banco 24 Horas',
                position: {
                    lat: -23.1053132,
                    lng: -47.0078908
                },
                icon: banco
            },
            {
                label: 'Banco Santader',
                position: {
                    lat: -23.1429291,
                    lng: -47.0215841
                },
                icon: banco
            },
            {
                label: 'Banco Bradesco / Banco Itaú',
                position: {
                    lat: -23.0871247,
                    lng: -46.9759477
                },
                icon: banco
            },
            {
                label: 'Banco Bradesco Itupeva',
                position: {
                    lat: -23.1517543,
                    lng: -47.0574152
                },
                icon: banco
            },
            {
                label: 'Banco do Brasil',
                position: {
                    lat: -23.155641,
                    lng: -47.0564067
                },
                icon: banco
            },
            {
                label: 'Banco Sicredi',
                position: {
                    lat: -23.155461,
                    lng: -47.0571953
                },
                icon: banco
            },
            {
                label: 'Itaú Personnalité',
                position: {
                    lat: -23.0348943,
                    lng: -46.9861865
                },
                icon: banco
            },
            {
                label: 'Banco do Brasil',
                position: {
                    lat: -23.0303919,
                    lng: -46.9729686
                },
                icon: banco
            },

        ]
    },
    diversao: {
        center: { lat: -23.0999705, lng: -47.0116533 },
        zoom: 14,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            {
                label: 'Wet"n Wild',
                position: {
                    lat: -23.1022317,
                    lng: -47.0103655
                },
                icon: diversao
            },
            {
                label: 'Hopi Hari',
                position: {
                    lat: -23.0977149,
                    lng: -47.0080316
                },
                icon: diversao
            },
            // {
            //     label: 'Casarão Garden Bar',
            //     position: {
            //         lat: -23.0868169,
            //         lng: -46.9811152
            //     },
            //     icon: diversao
            // },
            // {
            //     label: 'Parra Pub & Store',
            //     position: {
            //         lat: -23.0350757,
            //         lng: -46.9802103
            //     },
            //     icon: diversao
            // }
            {
                label: 'Shopping SerrAzul',
                position: {
                    lat: -23.1057736,
                    lng: -47.0085662
                },
                icon: diversao
            },
        ]
    },
    shopping: {
        center: { lat: -23.1077705, lng: -47.0116533 },
        zoom: 15,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            {
                label: 'Shopping SerrAzul',
                position: {
                    lat: -23.1057736,
                    lng: -47.0085662
                },
                icon: shopping
            },
            {
                label: 'Outlet Premium',
                position: {
                    lat: -23.1074402,
                    lng: -47.009511
                },
                icon: shopping
            }
        ]
    },
    hoteis: {
        center: { lat: -23.1077705, lng: -47.0116533 },
        zoom: 12,
        points: [
            {
                label: 'SerrAzul II',
                position: {
                    lat: -23.1077705,
                    lng: -47.0116533
                },
                icon: pin
            },
            {
                label: 'Quality Resort & Convention Center Itupeva',
                position: {
                    lat: -23.1033355,
                    lng: -47.01212
                },
                icon: hoteis
            },
            {
                label: 'Hotel Lago Azul',
                position: {
                    lat: -23.0912505,
                    lng: -46.9734704
                },
                icon: hoteis
            },
            {
                label: 'Hotel Maria Luíza',
                position: {
                    lat: -23.1437663,
                    lng: -47.0019615
                },
                icon: hoteis
            },
            {
                label: 'Hotel ITR SPA',
                position: {
                    lat: -23.1334994,
                    lng: -47.0378214
                },
                icon: hoteis
            }
        ]
    }
}
class PlacesOut extends Component {
    constructor() {
        super();
        this.state = {
            teste: '',
            maps: data.default
        }

        this.changePlaces = this.changePlaces.bind(this);
    };

    changePlaces(event) {
        event.preventDefault();
        let tag = event.target.nodeName;
        let type = '';

        tag = tag.toLowerCase();

        if (tag === 'a') {
            type = event.target.getAttribute('type');
        } if (tag === 'i') {
            type = event.target.parentNode.getAttribute('type');
        }

        this.setState({ maps: data[type] });

    };

    render() {
        return (
            <section id="places" className="section reset">
                <div className="content">
                    <h2>
                        Tudo o que você precisa,<br />
                        mais perto de você:
                    </h2>
                    <div className="flex">
                        <Map
                            id="g-maps"
                            options={{
                                center: this.state.maps.center,
                                zoom: this.state.maps.zoom
                            }}
                            onMapLoad={map => {

                                let info = [];
                                let infoWindow = new window.google.maps.InfoWindow();

                                this.state.maps.points.map(function (point, index) {

                                    info.push('<h1>' + point.label +'</h1>');

                                    let marker = new window.google.maps.Marker({
                                        position: point.position,
                                        title: point.label,
                                        map: map,
                                        icon: point.icon
                                    })

                                    window.google.maps.event.addListener(marker, 'click', (function(marker, index){
                                        return function(){
                                            infoWindow.setContent(info[index]);
                                            infoWindow.open(map, marker);
                                        }
                                    })(marker, index))
                                })
                            }}
                        />
                        <Aside change={this.changePlaces} />
                    </div>
                </div>
            </section>
        )
    }
}

class Map extends Component {
    componentDidMount() {
        this.mountMaps();
    };
    componentDidUpdate(){
        this.mountMaps();
    };

    mountMaps() {
        const map = new window.google.maps.Map(
            document.getElementById(this.props.id),
            this.props.options);
        this.props.onMapLoad(map)
    }

    render() {
        return (
            <div id={this.props.id} />
        )
    }
}

class Aside extends Component {
    render() {
        return (
            <aside>
                <nav>
                    <p>
                        Cruzando uma das nossas portarias você está a escassos minutos do centro de Itupeva. Você está na paz do paraíso e ao lado de tudo o que oferecem as grandes metrópoles:
                    </p>
                    <ul>
                        <li>
                            <a href="#" title="Mercados" onClick={this.props.change} type="supermercados">
                                <i className="places-carrinho"></i>
                                Mercados
                            </a>
                        </li>
                        <li>
                            <a href="#" title="Farmácias" onClick={this.props.change} type="farmacias">
                                <i className="places-farmacia"></i>
                                Farmácias
                            </a>
                        </li>
                        <li>
                            <a href="#" title="Escolas" onClick={this.props.change} type="escolas">
                                <i className="places-escolas"></i>
                                Escolas
                                        </a>
                        </li>
                        <li>
                            <a href="#" title="Padarias" onClick={this.props.change} type="padarias">
                                <i className="places-padaria"></i>
                                Padarias
                                        </a>
                        </li>
                        <li>
                            <a href="#" title="Bancos" onClick={this.props.change} type="bancos">
                                <i className="places-bancos"></i>
                                Bancos
                                        </a>
                        </li>
                        <li>
                            <a href="#" title="Diversão" onClick={this.props.change} type="diversao">
                                <i className="places-diversao"></i>
                                Diversão
                                        </a>
                        </li>
                        <li>
                            <a href="#" title="Shopping" onClick={this.props.change} type="shopping">
                                <i className="places-shopping"></i>
                                Shopping
                                        </a>
                        </li>
                        <li>
                            <a href="#" title="Hoteis" onClick={this.props.change} type="hoteis">
                                <i className="places-hoteis"></i>
                                Hoteis
                                        </a>
                        </li>
                    </ul>
                </nav>
            </aside>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyA5OuvcCJ_pV_jZem7kdhLNHwijwGn3CG8')
  })(PlacesOut)

// export default PlacesOut;