import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

//RESOURCES
import './../css/footer.scss';

//IMAGENS
import mktnow from './../images/logo-mktnow-white.png';


class Footer extends Component {
  render() {
    return (
      <footer id="footer" className="section reset">
        <div className="content">
          <div className="flex">
            <p>
              Copyright 2024. Senpar terras. Todos os direitos reservados.
            </p>
            <div className="share">
              <p>
                <a href="https://www.facebook.com/Fazenda-SerrAzul-184431132216190/" title="Facebok" target="_blank" rel="nofollow">
                  <FontAwesomeIcon icon={faFacebook}/>
                </a>
              </p>
              <p>
                <a href="https://www.instagram.com/fazenda_serrazul" title="Instagram" target="_blank" rel="nofollow">
                  <FontAwesomeIcon icon={faInstagram}/>
                </a>
              </p>
            </div>
            <figure>
              <a href="https://www.mktnow.com.br/" title="Agência de Performance Digital - Mktnow" target="_blank">
                <img src={mktnow} alt="Agência de Performance Digital - Mktnow" title="Agência de Performance Digital - Mktnow" />
              </a>
            </figure>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
