import React, { Component } from 'react';

//RESOURCES
import './../css/senpar.scss';

//IMAGENS
// import logo from './../images/logo-senpar.png';
import faixaMarromDesktop from './../images/faixa-marrom.png';
import faixaMarromMobile from './../images/faixa-marrom-mobile.png';

class Senpar extends Component {
    render() {
        return (
            <section id="senpar" className="section reset">
                <img className="faixa-institucional-desktop" src={faixaMarromDesktop} alt="Fazenda SerrAzul - Grupo Terras de São José" title="Fazenda SerrAzul - Grupo Terras de São José" />
                <img className="faixa-institucional-mobile" src={faixaMarromMobile} alt="Fazenda SerrAzul - Grupo Terras de São José" title="Fazenda SerrAzul - Grupo Terras de São José" />
            </section>
        );
    }
}

export default Senpar;
