import React, { Component } from 'react';
import validator from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import Button from 'react-validation/build/button';
import InputMask from 'react-input-mask';
import $ from 'jquery';
import WhatsAppModal from './WhatsAppModal';

//RESOURCES
import './../css/contact.scss';
import WhatsApp from './../images/contact-whatsapp.png';

//CUSTOM
const required = (value, props) => {
    if (!value || (props.isCheckable && !props.checked)) {
        return <span className="form-error is-visible">*Campo Obrigatório</span>;
    }
};
const email = (value) => {
    if (!validator.isEmail(value)) {
        return <span className="form-error is-visible">*E-mail não é valido</span>
    }
};

class Contact extends Component {

    constructor() {

        super();
        this.state = { nome: '', email: '', phone: '', sms: '', erroApi: '', activeErro: false };
        //FUNCTION BIND (THIS)
        this.SendForm = this.SendForm.bind(this);

        this.state = {
            modalActive: false,
            modalWhatsAppActive: false,
        }

        this.handleModal = this.handleModal.bind(this);
        this.handleWhatsAppModal = this.handleWhatsAppModal.bind(this);

    };

    salvaAlteracao(nomeInput, evento) {
        var campoSendoAlterado = {};
        campoSendoAlterado[nomeInput] = evento.target.value;
        this.setState(campoSendoAlterado);
    };


    handleModal() {
        this.setState({ modalActive: !this.state.modalActive });
    }
    handleWhatsAppModal() {
        this.setState({ modalWhatsAppActive: !this.state.modalWhatsAppActive });
    };
    SendForm(event) {
        event.preventDefault();

        // SCRIPT HOUSE CRM PARA FORMULARIO
        var nome = this.state.nome;
        var email = this.state.email;
        var phone = this.state.phone;
        var message = this.state.sms;
        var ddd = phone.substr(1, 2);
        var number = phone.substr(4).replace('-', "");

        const empreendimento = document.querySelector("meta[name=empreendimento]").content
        const origem = "LE"

        const objForm = {
            nome: nome,
            email: email,
            telefone: ddd + number,
            mensagem: message,
            codigo: empreendimento,
            origem: origem
        }

        console.log(objForm)

        var formData = new FormData();

        Object.keys(objForm).forEach((key) => {
            console.log(key, objForm[key])
            formData.append(key, objForm[key]);
        })

        fetch('testelead.php', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setTimeout(() => {
                    window.location.href = '/sucesso';
                }, 3000)
            })
            .catch(error => console.error('Erro durante a requisição:', error));


        // let _headers = new Headers();

        // _headers.append('Content-Type', 'application/json');

        // var config = {
        //     "async": true,
        //     "crossDomain": true,
        //     "url": "https://crm.anapro.com.br/webcrm/webapi/integracao/v2/CadastrarProspect",
        //     "method": "POST",
        //     "data": {
        //         "Key": "iYS41KZoeRg1",
        //         "CampanhaKey": "I-Tkuj8v7Q81",
        //         "ProdutoKey": "gJizs8B_X101",
        //         "CanalKey": "kyPodA0TNOQ1",
        //         "Midia": "Site Senpar Terras",
        //         "PessoaNome": nome,
        //         "PessoaEmail": email,
        //         "Mensagem": message,
        //         "KeyIntegradora": "19EF3BEC-4B3B-48E0-89BF-FB0C111D4FDA",
        //         "PessoaTelefones[0].DDD": ddd,
        //         "PessoaTelefones[0].Numero": number,
        //     }
        // }

        // $.ajax(config).done(function (response) {
        //     console.log(response);
        //     setTimeout(() => {
        //         window.location.href = '/sucesso';
        //     }, 3000)
        // });

    };

    render() {
        return (
            <>
                {this.state.modalWhatsAppActive && <WhatsAppModal modalWhatsAppActive={true} handleModal={this.handleWhatsAppModal} />}

                <section id="contact" className="section reset">
                    <div className="content">
                        <h2>
                            Loteamento Fazenda Serrazul.<br />
                            Qualidade de Vida para sua Família.
                        </h2>
                        <p>
                            <strong>
                                Venha conhecer o empreendimento.<br />
                                Agende uma visita e fique por dentro de cada detalhe.
                            </strong>
                        </p>
                        <div className="flex">
                            <Form id="form-contact" onSubmit={this.SendForm}>
                                <fieldset>
                                    <legend></legend>
                                    <div className="group">
                                        <label htmlFor="form-name">Nome</label>
                                        <Input type="text" id="form-name" name="form-name" value={this.state.nome} onChange={this.salvaAlteracao.bind(this, 'nome')} validations={[required]} />
                                    </div>
                                    <div className="group">
                                        <label htmlFor="form-email">E-mail</label>
                                        <Input type="text" id="form-email" name="form-email" value={this.state.email} onChange={this.salvaAlteracao.bind(this, 'email')} validations={[required, email]} />
                                    </div>
                                    <div className="group" id="phone">
                                        <label htmlFor="form-phone">Telefone</label>
                                        <InputMask mask="(99)99999-9999" type="text" id="form-phone" name="form-phone" value={this.state.phone} onChange={this.salvaAlteracao.bind(this, 'phone')} />
                                    </div>
                                    <div className="group">
                                        <label htmlFor="form-name">Mensagem</label>
                                        <Textarea id="form-sms" name="form-sms" value={this.state.sms} onChange={this.salvaAlteracao.bind(this, 'sms')} ></Textarea>
                                    </div>

                                    <div className="group">
                                        <Button>Enviar Mensagem</Button>
                                    </div>
                                </fieldset>
                                <div className={this.state.activeErro ? 'msgError isActive' : 'msgError'}>
                                    <p>
                                        <strong>Erro:</strong>
                                        {this.state.erroApi}<br />
                                        Por favor tente mais tarde estamos enfrentando problemas no nosso CRM
                                    </p>
                                </div>
                            </Form>
                            <div className="actions">
                                <figure>
                                    <img src={WhatsApp} alt="Fazenda SerrAzul - WhatsApp" title="Fazenda SerrAzul - WhatsApp" />
                                </figure>
                                <p>
                                    <span>Agenda uma visita</span>
                                    <strong>
                                        (11) 96406-2804
                                    </strong>
                                    <span>
                                        Atendimento de<br />
                                        Segunda a Domingo<br />
                                        das 8h às 20h
                                    </span>
                                    <a onClick={this.handleWhatsAppModal} title="Chamar no WhatsApp" className="whatsapp ga-whats" >
                                        Chamar no WhatsApp
                                    </a>

                                </p>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        );
    }
}

export default Contact;
