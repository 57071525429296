import React, { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faChevronDown, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

//RESOURCES
import './../css/chat-modal.scss';
import iconWhatsApp from './../images/scroll-whatsapp-anchor.png';

class WhatsAppModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            mail: '',
            phone: '',
            formSuccess: false,
            modalWhatsAppActive: this.props.modalWhatsAppActive
        }

        this.nameRef = createRef();
        this.mailRef = createRef();
        this.phoneRef = createRef();
        this.chatModalRef = createRef();
        this.closeModalButtonRef = createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePhoneMask = this.handlePhoneMask.bind(this);
    }

    componentDidMount() {
        const chatModal = this.chatModalRef.current;
        const closeModalButton = this.closeModalButtonRef.current;

        closeModalButton.addEventListener('click', () => {
            this.props.handleModal();
        });

        window.addEventListener('click', (e) => {
            if(e.target === chatModal) {
                this.props.handleModal();
            }
        });

        window.addEventListener('touchstart', (e) => {
            if(e.target === chatModal) {
                this.props.handleModal();
            }
        });
    }

    handlePhoneMask() {
        const currentPhoneValue = this.phoneRef.current;
        currentPhoneValue.value = currentPhoneValue.value.replace(/\D/g,"");
        currentPhoneValue.value = currentPhoneValue.value.replace(/^(\d{2})(\d)/g,"($1) $2");
        currentPhoneValue.value = currentPhoneValue.value.replace(/(\d)(\d{4})$/,"$1-$2");
    }

    handleSubmit(e) {
        e.preventDefault();

        let validationSuccess = true;
        
        const fields = [this.nameRef.current, this.mailRef.current, this.phoneRef.current];
        const regexEmail = (value) => !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
        const regexPhone = (value) => !/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/.test(value);

        fields.forEach(field => {
            if(!field.value ||
                (field.classList.contains('anapro-mail') && regexEmail(field.value)) ||
                (field.classList.contains('anapro-phone') && regexPhone(field.value))) {
                    field.classList.add('error');
                    validationSuccess = false;
                    return;
            }
            field.classList.remove('error');
        });

        if(validationSuccess) {
            const name = this.nameRef.current.value;
            const mail = this.mailRef.current.value;
            const phone = this.phoneRef.current.value;      
            const ddd = phone.substr(1, 2);
            const number = phone.substr(4).replace(/-/, '');

            const empreendimento = document.querySelector("meta[name=empreendimento]").content
            const origem = "WA"
    
            const objForm = {
                nome: name,
                email: mail,
                telefone: ddd + number,
                mensagem: '',
                codigo: empreendimento,
                origem: origem
            }
    
            console.log(objForm)
            
            var formData = new FormData();
    
            Object.keys(objForm).forEach((key) => {
                console.log(key, objForm[key])
                formData.append(key, objForm[key]);
            })

            const windowReference = window.open();
    
            fetch('testelead.php', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if(data){
                        windowReference.location = 'https://api.whatsapp.com/send?phone=5511964062804';
                        window.location.hash = 'zap-contato';
                        this.setState({ formSuccess: true });
                        return;
                    }
                })
                .catch(error => console.error('Erro durante a requisição:', error));

            
            // const windowReference = window.open();
            // const data = {
            //     "params": {
            //         "Key":"iYS41KZoeRg1",
            //         "CampanhaKey":"I-Tkuj8v7Q81",
            //         "ProdutoKey":"gJizs8B_X101",
            //         "CanalKey": "lcn_70lcUto1",
            //         "Midia":"WhatsApp",
            //         "UsuarioEmail": mail,
            //         "PessoaNome": name,
            //         "PessoaEmail": mail,
            //         "PessoaTelefones[0].DDD": ddd,
            //         "PessoaTelefones[0].Numero": number,
            //         "KeyIntegradora":"19EF3BEC-4B3B-48E0-89BF-FB0C111D4FDA",
            //         "KeyAgencia":"cad9a630-e1b1-409c-b2b2-eafce3f2f5f0",
            //     }        
            // };
        
            // let formBody = [];
            // for (const property in data.params) {
            //     const encodedKey = encodeURIComponent(property);
            //     const encodedValue = encodeURIComponent(data.params[property]);
            //     formBody.push(encodedKey + "=" + encodedValue);
            // }
            // formBody = formBody.join("&"); 

            // fetch('https://crm.anapro.com.br/webcrm/webapi/integracao/v2/CadastrarProspect', {
            //     method: 'POST',
            //     crossDomain: 'true',
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            //     },
            //     body: formBody,
            // }).then(response => {
            //     if(response.ok) {
            //         windowReference.location = 'https://api.whatsapp.com/send?phone=5511964062804';
            //         window.location.hash = 'zap-contato';
            //         this.setState({ formSuccess: true });
            //         return;
            //     }
            // }).catch(() => {
            //     alert('Ocorreu um erro, tente novamente!'); ;
            // }); 
        }
    }
    
    render(props) {
        return(
            <>
            {this.props.modalWhatsAppActive &&
                
                <div className="chat-modal" ref={this.chatModalRef}>
                    <div className="modal-content">
                        <span className="modal-close" ref={this.closeModalButtonRef}>
                            <FontAwesomeIcon icon={faTimes} size="1x" color="#fff" />
                        </span>
                        <div className="modal-header" id="whatsapp-header">
                            <img src={iconWhatsApp} alt="Iniciar WhatsApp" />
                            <h2>Entre em contato<br /> através do WhatsApp <FontAwesomeIcon icon={faChevronDown} /></h2>
                        </div>

                        <div className="form-success" style={this.state.formSuccess ? {display: 'flex'} : {}}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <h5>Obrigado pelo contato, você foi redirecionado para o WhatsApp!</h5>
                        </div>

                        <form className="modal-form" style={this.state.formSuccess ? {display: 'none'} : {}} onSubmit={this.handleSubmit}>
                            <div className="modal-field">
                                <div>
                                    <FontAwesomeIcon icon={faUser} className="modal-icon" />
                                    <label>digite seu nome</label>
                                </div>            
                                <input type="text" className="anapro-name" ref={this.nameRef} />
                            </div>
                        <div className="modal-field">
                            <div>
                                <FontAwesomeIcon icon={faEnvelope} className="modal-icon" />
                                <label>digite seu e-mail</label>
                            </div>            
                            <input type="text" className="anapro-mail" ref={this.mailRef} />
                        </div>
                        <div className="modal-field">
                            <div>
                                <FontAwesomeIcon icon={faPhone} className="modal-icon" />
                                <label>digite seu telefone</label>
                            </div>            
                            <input type="text" className="anapro-phone" ref={this.phoneRef} onChange={this.handlePhoneMask.bind(this)} maxLength="15" />
                        </div>
                            <button className={`anapro-btn${this.state.formSuccess ? ' anapro-success' : ''}`} type="submit">Enviar</button>
                        </form>
                    </div>
                </div>
            }
            </>
        );
    }
}

export default WhatsAppModal;
