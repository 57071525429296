import React, { Component } from 'react';

//RESOURCES
import './../css/benefits.scss';

class Benefits extends Component {
    render() {
        return (
            <section id="benefits" className="section reset">
                <div className="content">
                    <div className="text">
                        <h2>
                            O Empreendimento<br />
                            Fazenda SerrAzul
                        </h2>
                        <h3>
                            Lotes de 800 a 1.200 m² junto a natureza e perto de tudo.
                        </h3>
                    </div>
                    <div className="column">
                        <p>
                            O Empreendimento Fazenda Serrazul, conseguiu unir uma localização perfeita, natureza exuberante e lazer mais do que completo. Localizado a apenas 30 minutos de São Paulo pela Rodovia dos Bandeirantes, 10 minutos de Jundiaí e 5 minutos do centro de Itupeva, fazer parte do Polo Serrazul é muito mais que um diferencial, com acesso direto ao Outlet Premium, Quality Resort Itupeva, Shopping Aéro Serrazul, basta atravessar a rua e chegar ao internacional Wet´n Willd.
                            </p>
                        <p>
                            A Natureza foi generosa e o loteamento conta com uma enorme área verde preservada, um maravilhoso lago contemplativo, além de uma exuberante vista para a Serra do Japi!  O lazer está muito mais do que garantido com 4 quadras de tênis de saibro, campo de futebol com grama natural, piscina com raia de 25m, quadra poliesportiva, playground, fitness center e para os amantes de hipismo, o centro hípico está no sistema pay-per-use.
                        </p>
                    </div>
                    <ul>
                        <li>
                            <figure>
                                <i className="vigilancia"></i>
                                <figcaption>
                                    <h4>Vigilância 24 horas</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="quadra-tenis"></i>
                                <figcaption>
                                    <h4>4 Quadras de Tênis</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="campo-society"></i>
                                <figcaption>
                                    <h4>Campo de <br />Futebol Society</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="lagos"></i>
                                <figcaption>
                                    <h4>Lagos</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="energizacao"></i>
                                <figcaption>
                                    <h4>Energização Subterrânea</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="sistema-viario"></i>
                                <figcaption>
                                    <h4>Sistema Viário<br /> Inteligente</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="alamedas"></i>
                                <figcaption>
                                    <h4>Alamedas exclusivas</h4>
                                </figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <i className="quadra-poliesportiva"></i>
                                <figcaption>
                                    <h4>Quadra poliesportiva</h4>
                                </figcaption>
                            </figure>
                        </li>
                    </ul>
                    {/* <p>
                        <a href="#" title="Clique Aqui e baixe agora o folder do empreendimento com todas as informações">
                            Clique Aqui e baixe agora o folder do empreendimento com todas as informações
                        </a>
                    </p> */}
                </div>
            </section>
        );
    }
}

export default Benefits;
