import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';

//RESOURCES
import './../css/gallery.scss';
import 'react-image-lightbox/style.css';

//IMAGENS
import img1 from './../images/galeria-fotos-1.png';
import img1Big from './../images/galeria-fotos-1-big.png';
import img2 from './../images/galeria-fotos-2.png';
import img2Big from './../images/galeria-fotos-2-big.png';
import img3 from './../images/galeria-fotos-3.png';
import img3Big from './../images/galeria-fotos-3-big.jpeg';
import img4 from './../images/galeria-fotos-4.png';
import img4Big from './../images/galeria-fotos-4-big.png';
import img5 from './../images/galeria-fotos-5.png';
import img5Big from './../images/galeria-fotos-5-big.png';
import img6 from './../images/galeria-fotos-6.png';
import img6Big from './../images/galeria-fotos-6-big.png';
import img7 from './../images/galeria-fotos-7.png';
import img7Big from './../images/galeria-fotos-7-big.jpg';

//CUSTOM
const images = [
  img1Big,
  img2Big,
  img3Big,
  img4Big,
  img5Big,
  img6Big,
  img7Big,
];

class Gallery extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      photoIndex: 0,
    }
  }

  openGaleria = (index) => {
    console.log('clique imagem!');
    this.setState({
      isOpen: true,
      photoIndex: index
    })
  }

  render() {
    return (
      <section id="gallery" className="section reset">
        <div className="content">
          <h2>
            Em breve essa galeria<br />
            se tornará o seu dia a dia.
            </h2>
          <div className="galeria">
            <div className="column">
              <figure>
                {/* IMAGE 01 */}
                <img src={img1} alt="Fazenda SerrAzul - CHESA (pay-per-use) - Centro Hípico de Excelência" title="Fazenda SerrAzul - CHESA (pay-per-use) - Centro Hípico de Excelência" onClick={() => this.openGaleria(0)} />
              </figure>

              <figure>
                {/* IMAGE 04 */}
                <img src={img4} alt="Fazenda SerrAzul - Playgrounds" title="Fazenda SerrAzul - Playgrounds" onClick={() => this.openGaleria(3)} />
              </figure>
            </div>

            <div className="column">
              <figure>
                {/* IMAGE 02 */}
                <img src={img2} alt="Fazenda SerrAzul - Alamedas exclusivas" title="Fazenda SerrAzul - Alamedas exclusivas" onClick={() => this.openGaleria(1)} />
              </figure>

              <figure>
                {/* IMAGE 05 */}
                <img src={img5} alt="Fazenda SerrAzul - 4 Quadras de Tênis" title="Fazenda SerrAzul - 4 Quadras de Tênis" onClick={() => this.openGaleria(4)} />
              </figure>
            </div>

            <div className="column">
              <figure>
                {/* IMAGE 03 */}
                <img src={img3} alt="Fazenda SerrAzul - 4 Quadras de Tênis" title="Fazenda SerrAzul - 4 Quadras de Tênis" onClick={() => this.openGaleria(2)} />
              </figure>

              <figure>
                {/* IMAGE 06 */}
                <img src={img6} alt="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)" title="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)" onClick={() => this.openGaleria(5)} />
              </figure>

              <figure>
                {/* IMAGE 07 */}
                <img src={img7} alt="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)" title="Fazenda SerrAzul - Casarão Colonial SerrAzul (pay-per-use)" onClick={() => this.openGaleria(6)} />
              </figure>
            </div>
          </div>
        </div>
        {
          this.state.isOpen && (
            <Lightbox
              mainSrc={images[this.state.photoIndex]}
              nextSrc={images[(this.state.photoIndex + 1) % images.length]}
              prevSrc={images[(this.state.photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + 1) % images.length,
                })
              }
            />
          )
        }
      </section>
    );
  }
}

export default Gallery;
