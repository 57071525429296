import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//RESOURCES
import './../css/location-map.scss';

class LocationMap extends Component {
  render() {
    return (
      <section id="locationmap" className="section reset">
        <div className="content">
          <h2>
            Fazenda SerrAzul,<br />
            Perto de Tudo.<br />
            Com Vizinhos de Prestígio.
          </h2>
          <h3>
            A apenas 30 minutos de São Paulo e 10 minutos de Jundiaí. O Fazenda SerrAzul é um loteamento fechado de alto padrão com terrenos amplos e uma localização priveligiada.
          </h3>
          <p>
            <Link to="/localizacao" title="Ver localização">Ver localização</Link>
          </p>
        </div>
      </section>
    );
  }
}

export default LocationMap;
