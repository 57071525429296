import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import Congratulations from './components/Congratulations';
import Footer from './components/Footer';

class Success extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Parabéns, mensagem enviada com sucesso</title>
                </Helmet>

                <Header />

                <Congratulations />

                <Footer />
            </>
        )
    }
}

export default Success;
