import React from "react";
import '../css/timeline.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const timeline = (props) => {

    return <>
        <section class="timeline">
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-1963.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>19<span>63</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong>Criação </strong>Um projeto, para que seja realmente grande, deve ser capaz de distribuir sucesso a muitos. Com esse conceito amplo, o Engenheiro Jacob Federmann inicia as atividades da Senpar. <br/> Em 1967 a sociedade se consolida
                        com a chegada do Dr. Rosaldo Malucelli.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-1964.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>19<span>64</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong> Construção e
        Manutenção de eixos
        Importantes de
        Desenvolvimento </strong>Em um Brasil em pleno desenvolvimento a Senpar foi a responsável entre outras das seguintes rodovias: Castelo Branco, Anhanguera, Bandeirantes, Dom Pedro. <br/> Mais de cinquenta anos depois, a Senpar continua interconectando
                        cidades do Brasil todo.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-1974.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>19<span>73</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong> TERRAS DE <br/>
        SÃO JOSÉ </strong>Pioneirismo. Ousadia. Qualidade. Pilares do planejamento e posterior criação do primeiro loteamento fechado do Brasil: o reconhecido internacionalmente Terras de São José de Itu.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-1976.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>19<span>76</span></h3>
                        <h3>19<span>89</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong> Principais
        Loteamentos</strong>Entre os anos 70 até o fim dos anos 80, o grupo construiu algumas dezenas de loteamentos abertos e fechados, entre eles: São Pedro e São Paulo, Portela, Jardim Theodora, Terras de São José Guarujá e Terras de São Carlos. <br/> Mais
                        de 25.000 lotes desenvolvidos e entregues.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-1992.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>19<span>92</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong>Polo Serrazul </strong>Mais uma vez mostrando que o pioneirismo é uma marca forte no DNA da empresa, nos anos 90 inauguramos o primeiro shopping aéreo sobre rodovias da América Latina: o shopping SerrAzul.<br/> Logo após sua implantação
                        começa o desenvolvimento do Polo SerrAzul, sendo o primeiro polo turístico de São Paulo, com parques temáticos, hotél, shoppings, restaurantes e loteamentos fechados.<br/> Algumas das atrações do polo SerrAzul – Outlet Premium, Wet’n Wild,
                        Harley Davidson Store, Residencial Fazenda SerrAzul I e II, entre outros.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-1998.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>19<span>98</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong>Expansão De negócios </strong>O grupo cresce e expande seus negócios. Começa a era das concessões rodoviárias, entre elas a Renovias, Rodo Sul e MGO, entre outras.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-2006.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>20<span>06</span></h3>
                        <h3>20<span>16</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong>Novos Empreendimentos</strong>O grupo inaugura entre outros mais 5 empreendimentos que viraram referência no mercado imobiliário: Terras de São José II, Parque Ytu Xapada, Campos de Santo Antonio II, Villas do Golfe e Fazenda Serrazul
                        I e II.</p>
                </div>
            </article>
            <article class="timeline__year" style={{backgroundImage: `url('/images/timeline_bg-2018.png')`}} >
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>20<span>18</span></h3>
                    </div>
                    <p class="timeline__year-text" data-aos="fade-up-left"> <strong>Novotel itu Convention center </strong>O Grupo Senpar Terras de São José inaugura o melhor Hotel Resort do Interior de São Paulo. O Novotel Convention Center.</p>
                </div>
            </article>
            <article class="timeline__year timeline__year--end" style={{backgroundImage: `url('/images/timeline_bg-end.png')`}}>
                <Carousel className="mobile-s" showIndicators={false} showStatus={false} showThumbs={false} width={'100%'}>
                    <div class="timeline__text-wrapper">
                        <p>Loteamentos Fechados</p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Concessão de Rodovias </p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Construção de Estradas</p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Manutenção de Estradas</p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Obras de Grande Porte</p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Entretenimento</p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Hotelaria </p>
                    </div>
                    <div class="timeline__text-wrapper">
                        <p>Shoppings</p>
                    </div>
                </Carousel>
                <div class="timeline__year-card">
                    <div class="timeline__year-number" data-aos="fade-up-right">
                        <h3>Até os dias de hoje</h3>
                    </div>
                    <figure class="timeline__year-diagram mobile-h" data-aos="fade-up-left"><img src="./images/timeline_shapes-end.png" alt="Grupo Senpar Terras de São José II" title="Grupo Senpar Terras de São José II" /></figure>
                </div>
            </article>
        </section>

    </>

}

export default timeline;