import React, { Component } from 'react';

import './../css/congratulations.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileWink } from '@fortawesome/free-solid-svg-icons';

class Congratulations extends Component {
    render() {
        return (
            <>
                <section id="congratulations" className="section reset">
                    <div className="content">
                        <div className="text">
                            <p>
                                <FontAwesomeIcon icon={faSmileWink} size="6x" />
                            </p>
                            <h1>Parabéns, mensagem enviada com sucesso!</h1>
                            <p>
                                Agradecemos o contato e retornaremos em breve!
                    </p>
                            <p>
                                <a href="/" title="Continue navegando pelo site">Continue navegando pelo site</a>
                            </p>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Congratulations;