import React, { Component } from 'react';
import { Link } from 'react-scroll';
import ChatModal from './ChatModal';
import WhatsAppModal from './WhatsAppModal';

//RESOURCES
import './../css/scroll.scss';
import iconChat from './../images/scroll-chat-anchor.png';
import iconWhatsApp from './../images/scroll-whatsapp-anchor.png';
import iconCorretor from './../images/scroll-corretor-anchor.png';


class Scroll extends Component {
    constructor() {
        super();

        this.state = {
            modalActive: false,
            modalWhatsAppActive: false,
        }

        this.handleModal = this.handleModal.bind(this);
        this.handleWhatsAppModal = this.handleWhatsAppModal.bind(this);
    }

    handleModal() {
        this.setState({modalActive: !this.state.modalActive});
    }

    handleWhatsAppModal() {
        this.setState({modalWhatsAppActive: !this.state.modalWhatsAppActive});
    }
    
    render() {
        console.log(this.state.modalWhatsAppActive)
        return (
            <>
                <aside id="aside-scroll" className="section reset">
                    {/* <a onClick={this.handleModal} title="Fazenda SerrAzul - Iniciar Chat" className="chat-anchor">
                        <img src={iconChat} alt="Contato via Chat" title="Fazenda SerrAzul - Chat"/>
                        <small>Chat</small>
                    </a> */}
                    {this.state.modalActive && <ChatModal modalActive={true} handleModal={this.handleModal} />}
                    <a onClick={this.handleWhatsAppModal} title="Fazenda SerrAzul - Whatsapp" className="whatsapp ga-whats">
                        <img src={iconWhatsApp} alt="Contato via WhatsApp" title="Fazenda SerrAzul - WhatsApp" className="ga-whats"/>
                        <small>WhatsApp</small>
                    </a>
                    {this.state.modalWhatsAppActive && <WhatsAppModal modalWhatsAppActive={true} handleModal={this.handleWhatsAppModal} />}
                    <Link
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        duration={1000}
                        className="corretor"
                    >
                        <img src={iconCorretor} alt="Falar com corretor" title="Fazenda SerrAzul - Falar com corretor" />
                        <small>Fale com <br />corretor</small>
                    </Link>
                </aside>
            </>
        );
    }
}

export default Scroll;
